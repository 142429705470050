import React from 'react'

import SEO from '../components/SEO'
import CollectionGrid from '../components/CollectionGrid'
import PageHeader from '../components/Shared/PageHeader'

const breadcrumb = [
  { title: 'Home', url: '/' },
  { title: 'Shop', url: '/shop' },
]

const IndexPage = () => (
  <React.Fragment>
    <SEO
      title="Shop"
      keywords={[`raw dog food`, `poppys picnic`, `frozen dog food`]}
    />
    <PageHeader header="Raw Dog Food" breadcrumb={breadcrumb} />
    <div className="block">
      <div className="container">
        <div className="block">
          <div className="container">
            <div className="block">
              {/*<ProductsView products={products} layout="grid" grid="grid-5-full" />*/}
              <CollectionGrid />

              <div className="block-banner__button">
                <a className="btn btn-sm btn-primary" href="https://www.amazon.co.uk/s?k=frozen+dog+food&rh=n%3A471400031&dc&qid=1610015549&rnid=1642204031&tag=radofo-21&ref=sr_nr_n_1" target="_blank">Shop Now</a>
              </div>

            </div>
          </div>

          {/*<Product product={product} layout={layout} />
          <ProductTabs />
        
          <ProductsView products={products} layout={viewMode} grid={`grid-${columns}-full`} limit={15} />

          */}
        </div>
      </div>
    </div>
  </React.Fragment>
)

export default IndexPage
