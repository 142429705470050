import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Flex, Box } from '@rebass/grid/emotion'

import Image from 'gatsby-image'

const CollectionGrid = () => {
  const data = useStaticQuery(

    // allShopifyCollection(
    //   filter: {
    //     id: {
    //       eq: "Shopify__Collection__Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE1MDgyMDg4MDQ1Ng=="
    //     }
    //   }
    //   sort: { fields: [id], order: DESC }
    // ) {
    //   edges {
    //     node {
    //       id
    //       title
    //       products {
    //         shopifyId
    //         id
    //         title
    //         handle
    //         createdAt
    //         images {
    //           id
    //           originalSrc
    //           localFile {
    //             childImageSharp {
    //               fluid(
    //                 maxWidth: 910
    //                 traceSVG: { background: "#fff", color: "#bb2455" }
    //               ) {
    //                 ...GatsbyImageSharpFluid_withWebp_tracedSVG
    //               }
    //             }
    //           }
    //         }
    //         variants {
    //           price
    //         }
    //       }
    //     }
    //   }
    // }


    graphql`
      query {


        dataJson {
          products {
            shopifyId
            newHandle
            title
          }
        }
      }
    `
  )

  // console.log(data.allShopifyCollection.edges[0].node.products);
  // data.allShopifyCollection.edges[0].node.products = data.allShopifyCollection.edges[0].node.products.filter(x => {
  //   if(x.id === "Shopify__Product__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzE2NDg1MDg0NjkzMjA=") return false; // bow tie
  //   if(x.id === "Shopify__Product__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzM1OTM0NzY5MzE2NTY=") return false; // rawplicity guide
  //   return x;
  // });
  // data.allShopifyCollection.edges[0].node.products.sort((a,b) => (a.title > b.title) ? 1 : 0);
  // console.log(data.allShopifyCollection.edges[0].node.products);
  
  // data.allShopifyCollection.edges[0].node.products = data.allShopifyCollection.edges[0].node.products.map(
  //   product => {
  //     let meta = data.dataJson.products.filter(
  //       item => product.shopifyId === item.shopifyId
  //     )[0]
  //     if (meta) {
  //       product.title = meta.title
  //       product.handle = meta.newHandle
  //     }
  //     // product.meta = meta;
  //     return product
  //   }
  // )

  // console.log((data.allShopifyCollection.edges[0].node.products));

  return (
    <div
      className="products-view__list products-list"
      data-layout="grid-4-full"
      data-with-features="false"
    >
      <div className="products-list__body">
        {1===2 && data.allShopifyCollection.edges[0].node.products.map(x => (
          <div key={x.id} className="products-list__item">
            {/*<ProductCard product={product} />*/}

            <div className="product-card">
              {/*<AsyncAction
                      action={() => quickviewOpen(product.id)}
                      render={({ run, loading }) => (
                          <button
                              type="button"
                              onClick={run}
                              className={classNames('product-card__quickview', {
                                  'product-card__quickview--preload': loading,
                              })}
                          >
                              <Quickview16Svg />
                          </button>
                      )}
                  />
                            {badges}*/}
              <Link to={`/product/${x.handle}/`}>
                {x.images[0] && (
                  <Image
                    fluid={x.images[0].localFile.childImageSharp.fluid}
                    alt={x.handle}
                  />
                )}
              </Link>
              <div className="product-card__info">
                <div className="product-card__name">
                  <Link to={`/product/${x.handle}/`}>{x.title}</Link>
                </div>
              </div>
              {/*}
                  <div className="product-card__actions">
                      {price}
                      <div className="product-card__buttons">
                          <AsyncAction
                              action={() => cartAddItem(product)}
                              render={({ run, loading }) => (
                                  <React.Fragment>
                                      <button
                                          type="button"
                                          onClick={run}
                                          className={classNames('btn btn-primary product-card__addtocart', {
                                              'btn-loading': loading,
                                          })}
                                      >
                                          Add To Cart
                                      </button>
                                      <button
                                          type="button"
                                          onClick={run}
                                          className={classNames('btn btn-secondary product-card__addtocart product-card__addtocart--list', {
                                              'btn-loading': loading,
                                          })}
                                      >
                                          Add To Cart
                                      </button>
                                  </React.Fragment>
                              )}
                          />
                          <AsyncAction
                              action={() => wishlistAddItem(product)}
                              render={({ run, loading }) => (
                                  <button
                                      type="button"
                                      onClick={run}
                                      className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist', {
                                          'btn-loading': loading,
                                      })}
                                  >
                                      <Wishlist16Svg />
                                  </button>
                              )}
                          />
                          <AsyncAction
                              action={() => compareAddItem(product)}
                              render={({ run, loading }) => (
                                  <button
                                      type="button"
                                      onClick={run}
                                      className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare', {
                                          'btn-loading': loading,
                                      })}
                                  >
                                      <Compare16Svg />
                                  </button>
                              )}
                          />
                      </div>
                  </div>
                                    */}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CollectionGrid
